import { computed, reactive } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { termApi } from '../api/term';
export function useTermSearch({ enabled = false } = {}) { const searchArray = reactive([]); function addSearchParam(value = '') { searchArray.push(value); } function concatSearchArray(values) { searchArray.push(...values); } function clearSearchArray() { searchArray.splice(0, searchArray.length); } const { data: terms, refetch, isError, isSuccess, isLoading } = useQuery(['terms', searchArray], async () => { const responses = await Promise.all(searchArray.map(term => termApi.index(term))); return responses.flat(); }, { initialData: [], refetchOnWindowFocus: false, enabled }); const termsGroupedByClass = computed(() => terms.value.reduce((acc, term) => { if (acc[term.niceClassId]) {
    if (acc[term.niceClassId].terms.find(existingTerm => existingTerm.id === term.id)) {
        return acc;
    }
    acc[term.niceClassId].terms.push(term);
}
else {
    acc[term.niceClassId] = { id: term.niceClassId, number: term.niceClass.number, shortDescription: term.niceClass.shortDescription, explanatoryNote: term.niceClass.explanatoryNote, terms: [term] };
} return acc; }, {})); return { searchArray, addSearchParam, concatSearchArray, clearSearchArray, terms, termsGroupedByClass, refetch, isError, isSuccess, isLoading }; }
