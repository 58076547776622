import { inject, computed } from 'vue';
import { i18n } from 'utils/i18n';
import { userApi } from 'api/user';
export function useUser() {
    const user = inject('currentUser');
    const clearCurrentUser = inject('clearCurrentUser');
    const setCurrentUser = inject('setCurrentUser');
    const fullName = computed(() => { if (!user || !user.value)
        return ''; const nameParts = []; if (user.value.name)
        nameParts.push(user.value.name); if (user.value.lastName)
        nameParts.push(user.value.lastName); return nameParts.join(' '); });
    const loggedIn = computed(() => !!user?.value);
    async function logout() {
        try {
            await userApi.logout();
            clearCurrentUser?.();
            window.location.href = '/';
        }
        catch (error) { // eslint-disable-next-line no-alert
            alert(i18n.global.t('user.signOutError')); // eslint-disable-next-line no-console
            console.error(error);
        }
    }
    return { user, fullName, loggedIn, logout, setCurrentUser };
}
