import { createApp, provide, readonly, ref } from 'vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { camelizeKeys } from 'humps';
import InlineSvg from 'vue-inline-svg';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import App from './components/app.vue';
import BaseButton from './components/base-button.vue';
import BaseCheckboxInput from './components/base-checkbox-input.vue';
import BaseCheckbox from './components/base-checkbox.vue';
import BaseInput from './components/base-input.vue';
import BaseFileInput from './components/base-file-input.vue';
import BaseLabel from './components/base-label.vue';
import BaseSelect from './components/base-select.vue';
import BaseModal from './components/base-modal.vue';
import BaseMultiselect from './components/base-multiselect.vue';
import BaseMultitextInput from './components/base-multitext-input.vue';
import BaseCollapse from './components/base-collapse.vue';
import BaseRadio from './components/base-radio.vue';
import BaseRadioGroup from './components/base-radio-group.vue';
import BasePill from './components/base-pill.vue';
import BaseProgressBar from './components/base-progress-bar.vue';
import BaseLoader from './components/base-loader.vue';
import BaseAvatar from './components/base-avatar.vue';
import BaseCallout from './components/base-callout.vue';
import TrademarkCard from './components/trademark-card.vue';
import TrademarkApplicationChatbot from './components/trademark-application-chatbot.vue';
import TrademarkApplicationDashboard from './components/trademark-application-dashboard.vue';
import TermSearch from './components/term-search.vue';
import TrademarkSearch from './components/trademark-search.vue';
import LandingPage from './components/landing-page.vue';
import TheNavBar from './components/the-nav-bar.vue';
import TheNavSidebar from './components/the-nav-sidebar.vue';
import PaymentConfirmation from './components/payment-confirmation.vue';
import FrequentQuestions from './components/frequent-questions.vue';
import UserSession from './components/user-session.vue';
import { useSiteVariables } from './composables/useSiteVariables';
import { i18n } from './utils/i18n';
import './css/application.css';
document.addEventListener('DOMContentLoaded', () => {
    const globalComponents = { InlineSvg, BaseButton, BaseCheckboxInput, BaseCheckbox, BaseInput, BaseFileInput, BaseLabel, BaseSelect, BaseModal, BaseMultiselect, BaseMultitextInput, BaseCollapse, BaseRadio, BaseRadioGroup, BasePill, BaseProgressBar, BaseLoader, BaseCallout, BaseAvatar, TheNavBar, TheNavSidebar, TrademarkCard };
    const app = createApp({ components: { App, TermSearch, TrademarkSearch, LandingPage, TrademarkApplicationChatbot, PaymentConfirmation, FrequentQuestions, UserSession, TrademarkApplicationDashboard },
        setup() { function menuItemIsActive(hrefs, isRoot) { const currentPath = window.location.pathname; const currentHash = window.location.hash; if (isRoot) {
            return currentPath === '/' && currentHash === '';
        } return hrefs.some(href => currentPath === href || currentHash === href); } const menuItems = [{ label: 'navbar.home', href: '/', active: menuItemIsActive(['/'], true) }, { label: 'navbar.howItWorks', href: '/#how-it-works', active: menuItemIsActive(['#how-it-works']) }, { label: 'navbar.services', href: '/#services', active: menuItemIsActive(['#services']) }, { label: 'navbar.aboutUs', href: '/#about-us', active: menuItemIsActive(['#about-us']) }, { label: 'navbar.prices', href: '/#prices', active: menuItemIsActive(['#prices']) }]; provide('menuItems', readonly(menuItems)); const siteVariables = useSiteVariables(); const currentUser = ref(siteVariables.currentUser); provide('currentUser', currentUser); provide('clearCurrentUser', () => { currentUser.value = undefined; }); provide('setCurrentUser', user => { currentUser.value = user; }); }, data() { return { menuOpen: false }; }, methods: { camelizeKeys } });
    Object.entries(globalComponents).forEach(([name, component]) => { app.component(name, component); });
    app.use(i18n);
    app.use(VueQueryPlugin);
    app.use(FloatingVue, { themes: { 'user-menu': { $extend: 'dropdown' } } });
    app.mount('#vue-app');
    return app;
});
